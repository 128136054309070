/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseFollowUpStyledComponents from '../CaseFollowUpComponents/CaseFollowUpStyledComponents'
import CaseFollowUpJalonText from '../CaseFollowUpComponents/CaseFollowUpJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonMissionnement } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseFollowUpMissionJalonCardProps {
  jalon: JalonMissionnement;
}

const CaseFollowUpMissionJalonCard: React.FC<CaseFollowUpMissionJalonCardProps> = ({ jalon }) => {

  return (
    <Card>
      <CaseFollowUpStyledComponents.CardContent>
        <CaseFollowUpStyledComponents.Title>
          Réception du dossier par l'entreprise
        </CaseFollowUpStyledComponents.Title>
        <div />
        <CaseFollowUpJalonText
          jalon={jalon.type.code}
          date={jalon.missionnement?.dateModificationEtat || ''}
          state={jalon.etat}
          missionState={jalon.missionnement?.etatMissionnement}
        />
      </CaseFollowUpStyledComponents.CardContent>
    </Card>
  )
}

export default CaseFollowUpMissionJalonCard
