/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowDot from '../CaseFollowUpComponents/CaseFollowUpDot'
import CaseWorkflowStyledComponents from '../CaseFollowUpComponents/CaseFollowUpStyledComponents'
import CaseWorkflowDownloadButton from '../CaseFollowUpComponents/CaseFollowUpButtons/CaseFollowUpDownloadButton'
import CaseWorkflowJalonText from '../CaseFollowUpComponents/CaseFollowUpJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonFinDeTravaux } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowWorkEndJalonCardProps {
  jalon: JalonFinDeTravaux;
}

const CaseWorkflowWorkEndJalonCard: React.FC<CaseWorkflowWorkEndJalonCardProps> = ({ jalon }) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Fin de travaux
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.dateValidation || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonPhotoApresTravaux.etat}
            small
          />
          Photos après travaux
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonPhotoApresTravaux.photoApresTravaux.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              <CaseWorkflowStyledComponents.PicturesSubContainer>
                {
                  jalon.sousJalonPhotoApresTravaux.photoApresTravaux.map((file, index) => (
                    <CaseWorkflowDownloadButton
                      key={`${file.url}-${index}`}
                      name={getCleanFilename(file.libelle || '', file.fileName || '')}
                      url={file.url || ''}
                      isPhoto
                    />
                  ))
                }
              </CaseWorkflowStyledComponents.PicturesSubContainer>
            </CaseWorkflowStyledComponents.PicturesContainer> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonPhotoApresTravaux"
          date={jalon.sousJalonPhotoApresTravaux.dateValidation || ''}
          state={jalon.sousJalonPhotoApresTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonAttestationFinDeTravaux.etat}
            small
          />
          Procès-verbal de réception de travaux
        </CaseWorkflowStyledComponents.Line>
        <CaseWorkflowStyledComponents.PicturesContainer>
          <CaseWorkflowStyledComponents.PicturesSubContainer>
            {
              jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux ?
                <CaseWorkflowDownloadButton
                  name={getCleanFilename(jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.libelle || '', jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.nomDuFichier || '')}
                  url={jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.url || ''}
                /> :
                <div />
            }
          </CaseWorkflowStyledComponents.PicturesSubContainer>
        </CaseWorkflowStyledComponents.PicturesContainer>
        <CaseWorkflowJalonText
          jalon="sousJalonAttestationFinDeTravaux"
          date={jalon.sousJalonAttestationFinDeTravaux.dateValidation || ''}
          state={jalon.sousJalonAttestationFinDeTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonAttestationTva.etat}
            small
          />
          Attestation de TVA
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonAttestationTva.attestationTva?.length && jalon.sousJalonAttestationTva.attestationTva.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              <CaseWorkflowStyledComponents.PicturesSubContainer>
                {
                  jalon.sousJalonAttestationTva.attestationTva.map((file, index) => (
                    <CaseWorkflowDownloadButton
                      key={`${file.url}-${index}`}
                      name={getCleanFilename(file.libelle || '', file.nomDuFichier || '')}
                      url={file.url || ''}
                    />
                  ))
                }
              </CaseWorkflowStyledComponents.PicturesSubContainer>
            </CaseWorkflowStyledComponents.PicturesContainer> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonAttestationTva"
          date={jalon.sousJalonAttestationTva.dateValidation || ''}
          state={jalon.sousJalonAttestationTva.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowWorkEndJalonCard
