/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetMessagesRecipientListQuery,
  usePostNewMessageMutation,
} from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { useIsMobileLandscape } from 'helpers/hooks/useIsMedia'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { CodeLabel } from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const messageSchema = Yup.object({
  destinataires: Yup.mixed<CodeLabel>().test(
    'is-not-empty',
    'Le destinataire est obligatoire',
    (value) => isValidString(value?.code),
  ).required('Le destinataire est obligatoire'),
  objet: Yup.string().required("L'objet est obligatoire"),
  message: Yup.string().required('Le message est obligatoire'),
})

type MessageRequest = Yup.InferType<typeof messageSchema>

/* Styled components -------------------------------------------------------- */
const ButtonLine = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    gap: 0px;
    justify-content: space-between;
  }

  @media ${(props) => props.theme.media.mobile.landscape} {
    margin-top: 10px;
  }
`

const RecipientObjectContainer = styled.div`
  @media ${(props) => props.theme.media.mobile.landscape} {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
    margin-bottom: -15px;

    .new-message-input {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
`

const CustomTextField = styled(TextField)`
  @media ${(props) => props.theme.media.mobile.landscape} {
    margin-top: -5px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface MessagesNewMessageProps {
  caseId: string;
  setExpanded: (value: boolean) => void;
}

const MessagesNewMessage: React.FC<MessagesNewMessageProps> = ({
  caseId,
  setExpanded,
}) => {
  const isMobileLandscape = useIsMobileLandscape()

  const {
    currentData: recipients = [],
    isFetching: isFetchingRecipients,
  } = useGetMessagesRecipientListQuery(caseId)

  const [
    submitNewMessage,
  ] = usePostNewMessageMutation()

  const onSubmit = async (values: MessageRequest, { setSubmitting, resetForm }: FormikHelpers<MessageRequest>): Promise<void> => {
    const response = await submitNewMessage({
      caseId: caseId,
      data: {
        ...values,
        destinataires: [ values.destinataires.code ],
      },
    })

    if (!isApiError(response)) {
      resetForm()
      setExpanded(false)
    } else {
      setSubmitting(false)
    }
  }

  const formikForm = useForm<MessageRequest>(
    {
      initialValues: {
        destinataires: { code: '', libelle: '' },
        objet: '',
        message: '',
      },
      onSubmit: onSubmit,
      validationSchema: messageSchema,
    },
  )

  const recipientList: CodeLabel[] = useMemo((): CodeLabel[] => [ ...recipients ].sort((a: CodeLabel, b: CodeLabel): number => (a.libelle || '').localeCompare(b.libelle || '')), [ recipients ])

  const resetForm = () => {
    formikForm.resetForm()
    setExpanded(false)
  }

  return (
    <LoadingOverlay isLoading={isFetchingRecipients || formikForm.isSubmitting}>
      <Form form={formikForm}>
        <RecipientObjectContainer>
          <div>
            <FormBoldTitle
              className="new-message-input"
              required
            >
              Destinataire
            </FormBoldTitle>
            <AutocompleteField
              name="destinataires"
              options={recipientList}
              size="small"
              placeholder="Destinataire"
            />
          </div>
          <div>
            <FormBoldTitle
              className="new-message-input"
              required
            >
              Objet
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Objet"
              name="objet"
              size="small"
            />
          </div>
        </RecipientObjectContainer>
        <FormBoldTitle required>
          Message
        </FormBoldTitle>
        <Field
          component={CustomTextField}
          placeholder="Votre message"
          rows={isMobileLandscape ? 2 : 3}
          multiline
          name="message"
          size="small"
        />
        <ButtonLine>
          <LongButton
            variant="outlined"
            onClick={resetForm}
            size={isMobileLandscape ? 'small' : 'medium'}
          >
            Annuler
          </LongButton>
          <LongButton
            type="submit"
            variant="contained"
            size={isMobileLandscape ? 'small' : 'medium'}
          >
            Envoyer
          </LongButton>
        </ButtonLine>
      </Form>
    </LoadingOverlay>
  )
}

export default MessagesNewMessage
