/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseFollowUpComponents/CaseFollowUpStyledComponents'
import CaseWorkflowJalonText from '../CaseFollowUpComponents/CaseFollowUpJalonText'
import CaseWorkflowDownloadButton from '../CaseFollowUpComponents/CaseFollowUpButtons/CaseFollowUpDownloadButton'

/* Type imports ------------------------------------------------------------- */
import type { JalonFacture } from 'API/__generated__/Api'
import { EtatFacture } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowInvoiceCardProps {
  jalonFacture: JalonFacture;
}

const CaseWorkflowInvoiceCard: React.FC<CaseWorkflowInvoiceCardProps> = ({ jalonFacture }) => {
  const invoiceComment = (refuseMotivation?: string, total?: number) => {
    const montant = total ? `\nMontant TTC : ${total.toFixed(2)}€` : ''
    return `${montant}\n${refuseMotivation || ''}`
  }

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Facture
        </CaseWorkflowStyledComponents.Title>
        <CaseWorkflowStyledComponents.PicturesContainer>
          {
            !jalonFacture.factureSimplifiee && (jalonFacture.facture?.etat?.code === EtatFacture.Envoye || jalonFacture.facture?.etat?.code === EtatFacture.Renvoye) &&
            jalonFacture.piecejointe ?
              <CaseWorkflowDownloadButton
                name={getCleanFilename(jalonFacture.piecejointe?.libelle || '', jalonFacture.piecejointe?.fileName || '')}
                url={jalonFacture.piecejointe?.url || ''}
              /> :
              <div />
          }
        </CaseWorkflowStyledComponents.PicturesContainer>
        <CaseWorkflowJalonText
          jalon={jalonFacture.type.code}
          date={jalonFacture.dateAffichee || ''}
          state={jalonFacture.etat}
          invoiceState={jalonFacture.facture?.etat?.code}
          comment={invoiceComment(jalonFacture.facture?.motifRefus || '')}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowInvoiceCard
