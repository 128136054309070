/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowDot from '../CaseFollowUpComponents/CaseFollowUpDot'
import CaseWorkflowStyledComponents from '../CaseFollowUpComponents/CaseFollowUpStyledComponents'
import CaseWorkflowDownloadButton from '../CaseFollowUpComponents/CaseFollowUpButtons/CaseFollowUpDownloadButton'
import CaseWorkflowJalonText from '../CaseFollowUpComponents/CaseFollowUpJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonPostRdvMetre } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowPostMeasureJalonCardProps {
  jalon: JalonPostRdvMetre;
}

const CaseWorkflowPostMeasureJalonCard: React.FC<CaseWorkflowPostMeasureJalonCardProps> = ({ jalon }) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Post RDV métré
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.dateValidation || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonPhotoAvantTravaux.etat}
            small
          />
          Photos avant travaux
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonPhotoAvantTravaux.photoAvantTravaux.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              <CaseWorkflowStyledComponents.PicturesSubContainer>
                {
                  jalon.sousJalonPhotoAvantTravaux.photoAvantTravaux.map((file, index) => (
                    <CaseWorkflowDownloadButton
                      key={`${file.url}-${index}`}
                      name={getCleanFilename(file.libelle || '', file.fileName || '')}
                      url={file.url || ''}
                      isPhoto
                    />
                  ))
                }
              </CaseWorkflowStyledComponents.PicturesSubContainer>
            </CaseWorkflowStyledComponents.PicturesContainer> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonPhotoAvantTravaux"
          date={jalon.sousJalonPhotoAvantTravaux.dateValidation || ''}
          state={jalon.sousJalonPhotoAvantTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowPostMeasureJalonCard
