/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { useAuthInfo } from 'store/hooks'
import { getCleanFilename } from 'helpers/mediaUtils'
import { usePostDocumentsMutation } from 'store/api'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseFollowUpComponents/CaseFollowUpStyledComponents'
import CaseWorkflowJalonText from '../CaseFollowUpComponents/CaseFollowUpJalonText'
import CaseWorkflowDownloadButton from '../CaseFollowUpComponents/CaseFollowUpButtons/CaseFollowUpDownloadButton'
import CaseWorkflowAddDocumentButton from '../CaseFollowUpComponents/CaseFollowUpButtons/CaseFollowUpAddDocumentButton'

/* Type imports ------------------------------------------------------------- */
import type {
  DocumentDossierCreatePayload,
  JalonDevis,
} from 'API/__generated__/Api'
import {
  EtatDevis,
  TypeDocument,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowQuoteCardProps {
  jalonDevis: JalonDevis;
  caseId: string;
}

const CaseWorkflowQuoteCard: React.FC<CaseWorkflowQuoteCardProps> = ({ jalonDevis, caseId }) => {
  const navigate = useNavigate()
  const auth = useAuthInfo()
  const quoteComment = (refuseMotivation?: string, total?: number) => {
    const montant = total ? `\nMontant TTC : ${total.toFixed(2)}€` : ''
    return `${montant}\n${refuseMotivation || ''}`
  }
  const [
    submitNewDocument,
    { isLoading: isSendingFile },
  ] = usePostDocumentsMutation()

  const onClose = (file?: File) => {
    const data: DocumentDossierCreatePayload = { documentRequests: []}

    if (file) {
      data.documentRequests?.push({
        fichier: file,
        type: TypeDocument.DevisSigneAssure,
      })
    }

    submitNewDocument({
      caseId,
      data,
    }).catch(console.error)
  }

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Devis
        </CaseWorkflowStyledComponents.Title>
        <CaseWorkflowStyledComponents.PicturesContainer>
          {
            !jalonDevis.devisSimplifie && auth?.validerDevis && (
              jalonDevis.devis?.etat?.code === EtatDevis.Valide ||
              jalonDevis.devis?.etat?.code === EtatDevis.Regle) &&
            jalonDevis.pieceJointe ?
              <CaseWorkflowStyledComponents.DuoButtonsContainer>
                <CaseWorkflowAddDocumentButton
                  onClose={onClose}
                  disabled={isSendingFile}
                />
                <CaseWorkflowDownloadButton
                  name={getCleanFilename(jalonDevis.pieceJointe.libelle || '', jalonDevis.pieceJointe.fileName || '')}
                  url={jalonDevis.pieceJointe.url || ''}
                />
              </CaseWorkflowStyledComponents.DuoButtonsContainer> :
              !jalonDevis.devisSimplifie && (jalonDevis.devis?.etat?.code === EtatDevis.Transmis || jalonDevis.devis?.etat?.code === EtatDevis.Retransmis) && auth?.validerDevis ?
                <CaseWorkflowStyledComponents.Button
                  variant="contained"
                  onClick={() => navigate('devis', { state: { caseId: caseId }})}
                >
                  Voir et accepter le devis
                </CaseWorkflowStyledComponents.Button> :
                <div />
          }
        </CaseWorkflowStyledComponents.PicturesContainer>
        <CaseWorkflowJalonText
          jalon={jalonDevis.type.code}
          date={jalonDevis.dateAffichee || ''}
          state={jalonDevis.etat}
          quoteState={jalonDevis.devis?.etat?.code}
          comment={quoteComment(jalonDevis.devis?.motifRefus || '')}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowQuoteCard
